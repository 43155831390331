<template>
  <div></div>
</template>
<script>
export default {
  async mounted() {
    const fullPath = this.$route.fullPath.replace('/oaSso/redirect', '') || '/'
    // 已登录，直接重定向
    // const user = this.$store.state['user']
    // if (user.auth.accessToken) {
    //   try {
    //     // 校验token是否有效
    //     await this.$axios.post(
    //       '/ums/webhome/webAuths/authCode',
    //       {
    //         authCode: 'WEB',
    //       },
    //       {
    //         interceptors: {
    //           error: false,
    //         },
    //       }
    //     )
    //     this.$router.replace(fullPath)
    //   } catch (e) {
    //     this.$router.replace(`/oaSso/loginStart?redirect=${encodeURIComponent(fullPath)}`)
    //   }
    // } else {
    //   this.$router.replace(`/oaSso/loginStart?redirect=${encodeURIComponent(fullPath)}`)
    // }

    // 每次获取新token
    this.$router.replace(`/oaSso/loginStart?redirect=${encodeURIComponent(fullPath)}`)
  },
}
</script>
